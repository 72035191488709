<script setup>
import Index from '@/views/IndexVue.vue'
import { onMounted, reactive } from 'vue';
import { routes } from '@/router';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useRoute, useRouter } from 'vue-router';
const goUp = () => {
  $('html,body').animate({ scrollTop: 0 }, 500);
}
const isTopShow = () => {
  const route = useRoute();
  console.log(route.name)
}
isTopShow()
</script>

<template>
  <div id="quick_menu" alter="module/common/우측퀵메뉴">
    <ul>
      <!-- 전화상담 -->
      <li class="border_none tel">
        <a href="tel:010-8670-8275">
          <img src="/img/floating/phone.png">
          <span>전화상담</span>
        </a>
      </li>
      <!-- 온라인상담 -->
      <li>
        <router-link :to="{ 'name': 'nutrition' }">
          <img src="/img/floating/dish.png">
          <span>항암 식단</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ 'name': 'maps' }">
          <img src="/img/floating/map.png">
          <span>오시는 길</span>
        </router-link>
      </li>
      <li id="goTop">
        <a @click="goUp">
          <img src="/img/floating/top.png">
          <span>위로</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@import '@/assets/main.scss';
$logo_color: #00aa3a;

#quick_menu {
  margin: 0 0 20px 0;
  background: rgba(36, 21, 19, 0.8);
  border: solid 1px #fff;
  border-radius: 10px;
  position: fixed;
  right: 2%;
  bottom: 2%;
  z-index: 10;

  ul {
    width: 160px;
  }

  li {
    display: inline-block;

    :hover {
      scale: 1.1;
    }

    &:last-child img {
      width: 45%;
    }
  }

  a {
    display: block;
    padding: 12px;
    color: #fff;
    text-align: left;
  }

  img {
    width: 28%;
    height: 100%;
    padding: 5px;
    vertical-align: middle;
  }
}
@media screen and (max-width: 560px){
  #quick_menu img {
      width: 25% ;
      height: 100%;
      padding: 10px;
      vertical-align: middle;
      padding-top: 0;
    }
}
@media screen and (max-width: 1024px) {
  #quick_menu {
    bottom: 0;
    left: 0;
    width: 100%;
    border: 0;
    border-radius: 0;
    margin: auto;
    height: 80px;

    ul {
      text-align: center;
      width: 100%;
    }

    li {
      display: inline-block;
      width: 25%;

      :hover {
        scale: 1;
      }
    }

    a {
      display: block;
      padding: 12px 0;
      color: #fff;
      text-align: left;
      font-size: .7rem;
      text-align: center;
    }

    span {
      display: block;
    }

    img {
      width: 45%;
      height: 100%;
      padding: 10px;
      vertical-align: middle;
      padding-top: 0;
    }
  }
}


#app .text-left {
  text-align: left;
}
</style>
