import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import mouse from 'jquery.mousewheel'
import Meta from 'vue-meta'
import "@/assets/fonts.css"


createApp(App)
.use($)
.use(mouse)
.use(router)
.mount('#app')


/**
 * 
 * Meta, {
    title: '암스트롱요양병원',
    link: [{rel: "canonical", href: 'http://amstrong.kr/'}],
    meta:[
         { charset: 'utf-8' },
         { subject : '암스트롱요양병원'},
         { title : '암스트롱요양병원'},
         { name:"subject", content:"암스트롱요양병원"}
        ,{ name:"copyright" 		,content:"암스트롱요양병원"}
        ,{ name:"title" 			,content:"암스트롱요양병원"}
        ,{ name:"apple-mobile-web-app-title", content:"암스트롱요양병원"}
        ,{ name:"application-name" ,content:"암스트롱요양병원"}
        ,{ name:"description", content:"암 수술후 회복, 암환자 관리, 황토병실, 숲길산책 항암치료 관리를 위한 요양병원"}
        ,{ name:"keywords", content:"유방암,갑상선암,난소암,식도암,폐암,위암,자궁암,전립선암,간암,뇌종양,췌장암,대장암,방광암,담낭암,악성림프종,춘천요양병원,남양주요양병원,암요양병원,서울암요양병원,암"}
        ,{ property:"og:type",content:"website"}   
        ,{ property:"og:title", content:"암스트롱요양병원"}
        ,{ property:"og:site_name", content:"암스트롱요양병원"}
        ,{ property:"og:url", content:"http://amstrong.kr/"}
        ,{ property:"og:image" ,content:"http://amstrong.kr/thumb.jpg"}
        ,{ property:"og:image:width" ,content:"1200"}
        ,{ property:"og:image:height" ,content:"630"}
        ,{ property:"og:description", content:"암 수술후 회복, 암환자 관리, 황토병실, 숲길산책 항암치료 관리를 위한 요양병원"}
    ]
    
  
  }
 */