import { Carousel, Navigation, Slide } from 'vue3-carousel'
<script setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { reactive, onMounted,onUnmounted, onBeforeMount } from 'vue';

const field = reactive({
  img:'/img/slider_3_1.png'
})
const status = reactive({
  isMobile:false
})
const options = {
  settings: {
    itemsToShow: 1,
    snapAlign: 'left',
  },
  // breakpoints are mobile first
  //https://ismail9k.github.io/vue3-carousel/examples.html
  // any settings not specified will fallback to the carousel settings
  breakpoints: {
    580: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // 700px and up
    700: {
      itemsToShow: 2,
      snapAlign: 'center',
    },
    // 1024 and up
    1024: {
      itemsToShow: 3,
      snapAlign: 'center',
    },
  }
}

const pressOptions = {
  settings: {
    itemsToShow: 1,
    snapAlign: 'left',
  },
  // breakpoints are mobile first
  // any settings not specified will fallback to the carousel settings
  breakpoints: {
    580: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // 700px and up
    //700: {
    // itemsToShow: 2,
    //  snapAlign: 'center',
    //},
    // 1024 and up
    1024: {
      itemsToShow: 3,
      snapAlign: 'center',
    },
  }
}

const list = []
onBeforeMount(() => {
  for (let i = 1; i <= 31; i++) {
    list.push(
      `/img/carousel/batch_${i}.png`
    )
  }
  
})

const pressList = [
  {
    title: '암스트롱 요양병원, 취약계층 위해 3450만원 쾌척',
    logo: '/img/press/press_1_1.jpg',
    content: '춘천의 한 요양병원이 코로나19로 어려운 지역 내 교회와 취약계층을 위해 수천만원의 후원금을 쾌척했다. 춘천 서면에 있는 암스트롱 요양병원의 손의섭 이사장은 최근 춘천지역 교회 23곳에 월 50만원씩 3개월간 모두 3450만원을 지원하기로 결정했다.',
    url: '/press/press_1',
    type: 'I'
  },
  {
    title: '암스트롱 요양병원, 암환우 체험수기 공모전 시상식 개최',
    logo: '/img/press/press_2_1.jpg',
    content: "암환자 요양병원 '매그너스의료재단 암스트롱요양병원'이 지난 4월 4일, 암환우 체험 수기 당선작 시상식을 본원 비전센터에서 개최했다고 밝혔다. 우수상을 수상한 한연화 씨는 '암스트롱요양병원에 입원하여 15개월의 시간 동안 매일 약을 처방 받았다. 투병 중 힘들었던 일들도 많았지만 주치의 선생님과 가족들의 보살핌으로 잘 버텨낼 수 있었다'면서 '이러한 경험들이 책으로 출간되어 신기하기도 하고 뿌듯하다'고 소감을 전했다.",
    url: '/press/press_2',
    type: 'I'
  },
  {
    title: '암스트롱요양병원, ‘암을 극복한 사람들의 체험수기’ 발간',
    logo: '/img/press/press_3_1.jpg',
    content: '암스트롱 요양병원은 전국적으로 공모한 ‘암 환자 체험 수기 공모전’에서 수상한 암환자들의 체험 수기집인 ‘암을 극복한 사람들의 체험 수기’를 지난해 발간했다. 우리나라의 여성은 4명 중 1명, 남성은 3명 중 1명이 암으로 사망한다는 통계가 있듯이 암은 이젠 흔한 질병이 되었다. 암이라는 병은 본인은 물론 가족들과 대부분의 사람에게 완치되기 힘들다고 알려져 암이라고 병원에서 선고를 받으면 그 순간이 마치 사형선고를 받은 것처럼 크게 실망하곤 한다. 하지만 더는 암은 사형선고가 아닌 꾸준한 관리로 극복 가능한 ‘관리암’이라고 불리기도 한다. 더는 남일만이 아닌 암 투병생활에 대한 진솔한 그들의 이야기를 통하여 고충, 희망, 용기, 암을 극복할 수 있던 치열한 일상과 애환을 엮은 ‘암을 극복한 사람들의 체험 수기’가 출간되어 잔잔한 화제가 되고 있다.',
    url: '/press/press_3',
    type: 'I'
  },
  {
    title: '암스트롱요양병원, 미FDA 승인 고주파 온열암치료기 도입',
    logo: '/img/press/press_4_1.jpg',
    content: '매그너스 의료재단(이사장 손의섭)산하 암스트롱요양병원이 5월부터 최신 고주파온열암치료기(BSD-2000)를 도입하여 본격 운영에 들어간다. 미국 FDA(미국식품의약안정청) 승인을 받은 후 2014년 국내 KFDA 인가까지 완료한 고주파 온열암치료기 4채널 BSD-2000(Deep Hyperthermia System)은 심부의 종양치료의 온도 및 열 체크가 가능하여 실시간으로 종양치료부위의 온도를 확인하여 환자 개개인의 상태에 맞는 치료 계획을 세울 수 있다.',
    url: '/press/press_4',
    type: 'I'
  },
  {
    title: '암스트롱요양병원, 암환우 위한 ‘희망 나눔 찾아가는 음악회’ 개최',
    logo: '/img/press/press_5_1.jpg',
    content: '암요양병원 암스트롱요양병원은 오는 31일 오후 7시에 암환우들을 위한 ‘희망 나눔 찾아가는 음악회’를 개최한다. 이번 음악회는 병상에서 무더위에 지친 환우들 및 보호자들의 마음을 달래고, 치료에 따른 스트레스와 피로를 풀 수 있는 힐링의 시간을 선물하기 위해 마련됐다.',
    url: '/press/press_5',
    type: 'I'
  },
  {
    title: '암환우를 위한 통합 치료로 각광받아',
    logo: '/img/press/press_6_1.jpg',
    content: '암환자 관리전문병원으로 집에서는 어려운 전문적인 관리 및 암환자에게 적절한 치료뿐 아니라 삶의 질을 높여 줄 수 있는 곳으로 소문이 나있는 곳이 있어 화제다. 암스트롱요양병원은 암환자 전문관리병원으로 보다 전문성을 높이기 위해 각 분야 전문가로 구성된 통합의학 암치료센터를 개설하여 운영중이다. 항암에 대한 양·한방 보조치료와 면역치료, 암환우 전문 영양사가 제공하는 친환경 건강식사, 니시의학에서 출발한 니시운동과 풍욕, 체조 등 암환우에게 맞춘 전문적인 관리를 하고 있다.',
    url: '/press/press_6',
    type: 'I'
  },
  {
    title: "암스트롱요양병원, 보건복지부 '의료기관 인증' 획득",
    logo: '/img/press/press_7_1.jpg',
    content: '의료법인 매그너스의료재단 암스트롱요양병원(이사장 손의섭)이 보건복지부 산하 의료기관평가인증원으로부터 인증의료기관을 획득해 눈길을 끈다. 의료기관 인증제란 의료기관의 환자 안전 수준과 의료 서비스 질을 국가에서 인증하는 제도로, 의료기관평가 인증원이 이틀에 걸쳐 의료 서비스 및 환자 안전, 소방, 시설 등 여러 분야를 세밀한 기준을 바탕으로 현장 인증 평가를 하게 된다.',
    url: '/press/press_7',
    type: 'I'
  },
  {
    title: '암요양병원 암스트롱요양병원, "친환경 유기농 식단 통해 암 치료 대비해야"',
    logo: '/img/press/press_8_1.jpg',
    content: '"제가 ㅇㅇ암인데 어떤 음식, 반찬을 많이 먹어야 할까요?" "영양사님, ㅇㅇ이 너무 먹고 싶은데 먹어도 될까요?"',
    url: '/press/press_8',
    type: 'I'
  },
  {
    title: '암스트롱요양병원 "산책로 걷다 보면 희망이 생겨요"',
    logo: '/img/press/press_9_1.jpg',
    content: '오후엔 암스트롱요양병원 환우들을 뒷산 등산로와 산책로에서 쉽게 만날 수 있다. 멀리서 보면 산악회 회원들의 아지트가 아닌가 싶을 정도로 아늑하고 편안한 분위기를 띄는 병원이 있다.',
    url: '/press/press_9',
    type: 'I'
  },
  {
    title: "피톤치드 가득한 '산 속 등산', 암요양병원 암 치유 방법으로 각광",
    logo: '/img/press/press_10_1.jpg',
    content: '[생활정보]  암의 원인 중 하나는 체내 독소다. 해독이 되지 않고 노폐물이 배설되지 않으면 몸에 남아있는 독소는 피를 더럽게 만들고 산소 공급이 줄어 활성 산소와 수소 이온 농도가 증가되어 몸이 산성화되고 면역계가 무너지며 결국 각종 염증 질환이나 암과 같은 질병을 유발하게 된다. 그래서 암환자들에게는 편백나무 숲에서 방출되는 피톤치드가 특히 인기가 높다. 마음까지 시원해지는 그 향에 스트레스는 물론 두통과 항암 후 해독까지 효과가 있기 때문이다.',
    url: '/press/press_10',
    type: 'I'
  },
  {
    title: '2020 조선일보 선정 국가브랜드대상 수상자 소개(4)',
    logo: '/img/press/press_11_2.jpg',
    content: '경기도 남양주시 수동면에 있는 매그너스레지덴샬은 요양병원 전문기업인 매그너스의료재단의 의료와 케어(돌봄)를 연계한 실버타운이다.',
    url: '/press/press_11',
    type: 'I'
  },
  {
    title: '암요양병원 암스트롱요양병원, 조선일보 최고경영대상 수상(고객중심경영)',
    logo: '/img/press/press_12_1.jpg',
    content: '',
    url: 'https://youtu.be/cFOPC7akNUsC',
    type: 'V'
  },
  {
    title: "MBC퀸 '암과 나' 5회 암스트롱요양병원 방영",
    logo: '/img/press/press_13_1.jpg',
    content: '',
    url: 'https://youtu.be/zYrvzGrDJAk',
    type: 'V'
  },
  {
    title: '암환우를 위한 암스트롱요양병원의 유기농 농장',
    logo: '/img/press/press_14_1.jpg',
    content: '',
    url: 'https://youtu.be/SbToh_oK8jI',
    type: 'V'
  },
  {
    title: "[암요양병원]암스트롱 요양병원-'TV조선 건강다큐' 송성애 환우 출연",
    logo: '/img/press/press_15_1.jpg',
    content: '',
    url: 'https://youtu.be/Qvzy46l2K8Y',
    type: 'V'
  },
]

const shuffledList = pressList.sort(() => Math.random() - 0.5);

function noshow(event){
  event.target.style.display='none'
}

function resize(){
  console.log(window.outerWidth)
  if(window.outerWidth <= 800){
    field.img = '/img/slider_3_2.png'
  }else{
    field.img = '/img/slider_3_1.png'
  }
}
onUnmounted(()=>{
  window.removeEventListener('resize',resize)
  document.body.style.overflowY = 'auto';
})

onMounted(() => {
  document.body.style.overflowY = 'hidden';
  window.addEventListener('resize',resize)
  const $slider = $('.slider');
  $slider.slick({
    // rows:1,
    // slidesToShow:3,
    // autoplaySpeed:2000,
    infinite: false,
    loop: false,
    arrows: false,
    vertical: true,
    dots: true,
    speed: 800,
    autoplay: false,
    cssEase: 'cubic-bezier(0.86, 0, 0.07, 1)',
  });

  $slider.mousewheel(function (e) {
    e.preventDefault();
    console.log(e.deltaY);
    if (e.deltaY < 0) {

      if ($(this).slick('slickCurrentSlide') === $(this).find('.slick-slide').length - 1) {
        return;
      }

      $(this).slick('slickNext');

    } else {

      if ($(this).slick('slickCurrentSlide') === 0) {
        return;
      }

      $(this).slick('slickPrev');
    }
  });
});



</script>
<template>
  <!-- html -->
  <div class="slider">
    <template v-if="status.isMobile">
      <div class="door" style="display:none">
      <div class="section5">
        <div class="description" style="overflow-x:hidden">
          <h3>환우님을 위한 쾌적한 시설</h3>
          <div class="info">
            <ul>
              <li class="first">주소 : 강원 춘천시 서면 율장길 328 (안보리 312)</li>
              <li>대표: 손의섭</li>
              <li>사업자등록번호 : 223-82-03590</li>
              <li>고객센터: 033-263-8275</li>
            </ul>
            <p>Copyright © 암스트롱요양병원. All rights reserved. </p>
          </div>
        </div>
        <br />
      </div>
    </div>
    </template>
    <div class="door">
      <div class="section1">
        <div id="video">
          <div class="descriptionBox">
            <div class="description">
              <!-- <h3>암스트롱 요양병원</h3> -->
              <div class="section1_box">
                <img class="res" src="/logo_main_white.png" alt="">
                <div class='txt01 text-center'>
                  환자별 맞춤식 의료서비스를 제공하는
                  <br />프라임급, 최고의 요양병원 입니다.
                </div>
                <div class="btn">
                  <RouterLink :to="{ name: 'info' }">
                    자세히 보기
                  </RouterLink>
                </div>
              </div>

            </div>
          </div>
          <div id="home" class="parallax-section">
            <!-- Video -->
            <video width="1000" height="500" autoplay="" loop="" muted="" playsinline="">
              <source src="img/hblock/design/video/img/video.mp4" type="video/mp4">
            </video>
          </div>
        </div>
      </div>
    </div>


    <div class="door">
      <div class="section2" style="text-align: right;">
        <div class="description">
          <h3>암환우를 위한 최고의 치료기관</h3>
          <div class='txt01'>
            마음을 다하고 성품을 다하여
            <br />환자를 섬기는 최고의 병원
          </div>
          <div class="btn">
            <RouterLink :to="{ 'name': 'center' }" style="margin-left: auto;">자세히 보기</RouterLink>
          </div>
        </div>
      </div>
    </div>

    <div class="door">
      <div class="section6">
        <div class="description" style="overflow-x:hidden">
          <h3>미디어 속 암스트롱</h3>
          <carousel class="description" :settings="pressOptions.settings" :breakpoints="pressOptions.breakpoints"
            :wrap-around="true" autoplay="2000">
            <Slide v-for="slide in shuffledList" :key="slide">
                <div v-if="slide.type ==='V' " class="carousel__item" style="align-items: flex-start;">
                  <a :href="slide.url" target="_blank">
                    <div class="pressThumbnail" :style="{ backgroundImage : `url(${slide.logo})`}">
                      <!-- <img :src="slide.logo"> -->
                    </div>
                    <div class="pressTitle">
                      <p>{{slide.title}}</p>
                    </div>
                  </a>
                </div>
                <div v-else class="carousel__item" style="align-items: flex-start;">
                  <a :href="slide.url">
                    <div class="pressThumbnail" :style="{ backgroundImage : `url(${slide.logo})`}">
                      <!-- <img :src="slide.logo"> -->
                    </div>
                    <div class="pressTitle">
                      <p>{{slide.title}}</p>
                    </div>
                    <div class="pressContent">
                      <p>{{slide.content}}</p>
                    </div>
                  </a>
                </div>
              </Slide>
            <template #addons>
              <Navigation />
              <pagination />
            </template>
          </carousel>
        </div>
        <br />
      </div>
    </div>

    <div class="door">
      <div class="section3">
        <div class="description">
          <h3>환자 개개인에게 맞춘 통합적인 관리</h3>
          <div class="slider_3">
            <img class="slider_3_img" :src="field.img" alt="">
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="door">
      <div class="section4">
        <div class="description" style="overflow-x:hidden">
          <h3>환우님을 위한 쾌적한 시설</h3>
          <carousel class="description" :settings="options.settings" :breakpoints="options.breakpoints"
            :wrap-around="true" autoplay="2000">
            <Slide v-for="slide in list" :key="slide">
              <div class="carousel__item">
                <img @error="noshow" :src="slide" style="border-radius: 8px;width: 98%;height: 100%;" />
              </div>
            </Slide>
            <template #addons>
              <Navigation />
              <pagination />
            </template>
          </carousel>
        </div>
        <br />
      </div>
    </div>
  </div><!-- SLIDER -->
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/main.scss";
.slider_3{
  width: 100%;
}
.slider_3_img{
  width:inherit;
  padding:0 15px;
}
 
.description {
  .carousel__icon {
    scale: 3;
    opacity: .6;
  }

  .carousel__prev,
  .carousel__next {
    border: 0;
  }
}

@import '@/img/hblock/design/slide_06/css/slick-theme.min.css';
/* 옆에 숫자뜨는거 */

// VD
@import '@/img/hblock/design/video/css/style.css';

//SECTION 1
@import "@/img/hblock/design/slide_06/css/style.css";

//SECTION 2
@import "@/img/hblock/design/block_07/css/style.css";

//section 3
@import "@/img/hblock/design/product_04/css/style.css";

//SECTION 4
@import "@/img/hblock/design/block_08/css/style.css";

//section 5
@import "@/img/hblock/design/product_03/css/style.css";

/* 캐러셀 메뉴  */
@import "@/img/hblock/design/menu_04/css/menu.css";

// @media (max-width: 560px) {
//   #app img.res{
//     width:60%;
//   }
// }
.carousel__icon{
  scale: 3;
  color: white;
}
@media (max-width: 860px) {
  #app img.res {
    width: 60%;
  }
}

.slider .door {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
}

.carousel__item {
  min-height: 200px;
  max-height: 500px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.section1_box {
  width: 40%;
  text-align: center;

  .btn a {
    margin: auto;
  }

  @media (max-width: 1023px) {
    width: 80%;
    margin: auto;
  }
}
#footer_03{
  display: none !important;
}
#app{
//   .section3 .icon section.Box_01 a {
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
//     filter: grayscale(1);
// }
}


//SECTION 1
</style>
