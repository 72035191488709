<script setup>
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps(['footerlist']);
const router = useRouter();
const footer = reactive({
  class : ''
})
onMounted(() => {
})

router.beforeEach((to, from, next) => {
  console.log('footer beforeEach to index')
  if (to.fullPath === '/') {
    footer.class = 'index_page'
  } else {
    footer.class = ''
  }
  next()
})
</script>
<template>
  <div id="footer_03" :class="footer.class">
    <div class="footer_wrap wd12">
      <div class="sns">
        <a :href="item.link" v-for="item in props.footerlist" target="_blank">
          <img :src="item.src" :alt="item.alt" style="width: 36px;height:36px;">
        </a>
      </div>
      <div class="info">
        <ul>
          <li class="first">주소 : 강원 춘천시 서면 율장길 328 (안보리 312)</li>
          <li>대표: 손의섭</li>
          <li>사업자등록번호 : 223-82-03590</li>
          <li>고객센터: 033-263-8275</li>
        </ul>
        <p>Copyright © 암스트롱요양병원. All rights reserved. </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/img/hblock/design/footer_03/css/style.css";
@media (max-width: 1025px) {
  .index_page{
    display: none;
  } 
}
.index_page {
  .info {
    position: absolute;
    bottom: 0;
    /* height: 180px; */
    z-index: 10000;
    color: white;
    visibility: visible;
    margin-bottom: 10px;
  }
}

.sns a:first-child img {
  opacity: 0.6;
}

.footer_wrap {

  padding-bottom: 60px;
}

.sns a:last-child img {
  width: 36px;
  height: 36px;
  background: black;
  border-radius: 18px;
  opacity: 0.6;
}</style>