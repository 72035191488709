<script setup>
import Index from '@/views/IndexVue.vue'
import { onMounted, reactive } from 'vue';
import { routes } from '@/router';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import QuickMenu from './components/QuickMenu.vue';


const header = []
routes.forEach(ele => {
  let path = ele.path
  if (ele?.children) {
    let cList = []
    for (let cha of ele.children) {
      cList.push({
        title: cha.meta.title,
        link: "/" + cha.path
      })
    }
    header.push({
      title: ele.meta.title,
      link: ele.path,
      subList: cList
    })
  }
})

const footer = reactive([
  {
    alt: '전화 바로가기',
    src: '/img/sns/side/ico_phone.png',
    subSrc: '/img/sns/side/ico_phone.png',
    link: 'tel:010-8670-8275'
  },
  {
    alt: '블로그 바로가기',
    src: '/img/sns/footer/blog2.png',
    subSrc: '/img/sns/side/blog2.png',
    link: 'https://blog.naver.com/placeglad98'
  },
  {
    alt: '홈페이지 바로가기',
    src: '/img/sns/side/home.png',
    subSrc: '/img/sns/side/home.png',
    link: '/'
  },


])
</script>

<template>
  <QuickMenu />
  <Header role="header" :headerlist="header" :footerlist="footer"></Header>
  <RouterView role="routerView"></RouterView>
  <Footer role="footer" :footerlist="footer"></Footer>


  <a hidden href="/info/info/index.html">암스트롱요양병원</a>					
  <a hidden href="/info/mission/index.html">미션과비전</a>					
  <a hidden href="/info/accomo/index.html">시설안내</a>		
  <a hidden href="/info/scenery/index.html">병원풍경</a>			
  <a hidden href="/info/partners/index.html">협력병원소개</a>				
  <a hidden href="/info/maps/index.html">오시는길</a>		
  <a hidden href="/info/press/index.html">언론보도</a>				
  <a hidden href="/center/center/index.html">통합의학암센터</a>				
  <a hidden href="/center/program/index.html">의학프로그램</a>				
  <a hidden href="/center/fregency/index.html">고주파온열치료</a>				
  <a hidden href="/tripple/tripple/index.html">삼위일체+1요법</a>			
  <a hidden href="/tripple/tripple/index.html">보완대체의학</a>				
  <a hidden href="/tripple/nutrition/index.html">영양요법</a>				
  <a hidden href="/tripple/nature/index.html">자연요법</a>					
  <a hidden href="/customer/customer/index.html">비급여치료비안내</a>			
  <a hidden href="/customer/service/index.html">입원 및 상담안내</a>			
   
</template>
<style lang="scss">
@import '@/assets/main.scss';
$logo_color: #00aa3a;
</style>
