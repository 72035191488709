import { createRouter, createWebHistory } from 'vue-router'
import IndexVueVue from '@/views/IndexVue.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: ()=> import('@/views/IndexVue.vue')
  },
  {
    path: '/info',
    meta: {title: '병원소개'},
    children: [
      {
        path: 'info',
        name: 'info',
        meta: { title: '암스트롱요양병원' },
        component: () => import('@/views/info/InfoView.vue'),
      },
      {
        path: 'mission',
        name: 'mission',
        meta: { title: '미션과비전' },
        component: () => import('@/views/info/MissionView.vue'),
      }, 
      {
        path: 'accomo',
        name: 'accomo',
        meta: { title: '시설안내', },
        component: () => import('@/views/info/AccomoView.vue'),
      },
      {
        path: 'scenery',
        name: 'scenery',
        meta: { title: '병원풍경', },
        component: () => import('@/views/info/SceneryView.vue'),
      },
      {
        path: 'partners',
        name: 'partners',
        meta: { title: '협력병원소개' },
        component: () => import(
            '@/views/info/PartnersView.vue'
        ),
      },
      {
        path: 'maps',
        name: 'maps',
        meta: { title: '오시는길' },
        component: () => import('@/views/info/MapsView.vue'),
      },
      {
        path: 'press',
        name: 'press',
        meta: { title: '미디어 속 암스트롱' },
        component: () => import('@/views/info/PressView.vue'),
      },
    ],
  },
  {
    path: '/center',
    meta: {title: '통합의학암센터'},
    children: [
      {
        path: 'center',
        name: 'center',
        meta: { title: '센터소개' },
        component: () => import('@/views/center/CenterView.vue'),
      },
      {
        path: 'program',
        name: 'program',
        meta: { title: '의학프로그램' },
        component: () => import('@/views/center/ProgramView.vue'),
      },
      {
        path: 'fregency',
        name: 'fregency',
        meta: { title: '고주파온열치료' },
        component: () => import('@/views/center/HighFrequency.vue'),
      },
    ],
  },
  {
    path: '/tripple',
    meta: {title: '삼위일체+1요법'},
    children: [
      {
        path: 'tripple',
        name: 'tripple',
        meta: { title: '보완대체의학' },
        component: () => import('@/views/tripple/CamView.vue'),
      },
      {
        path: 'nutrition',
        name: 'nutrition',
        meta: { title: '영양요법' },
        component: () => import('@/views/tripple/NutritionView.vue'),
      },
      {
        path: 'nature',
        name: 'nature',
        meta: { title: '자연요법' },
        component: () => import('@/views/tripple/NatureView.vue'),
      },
    ],
  },
  {
    path: '/customer',
    meta: {title: '고객상담'},
    children: [
      {
        path: 'customer',
        name: 'customer',
        meta: { title: '비급여치료비안내' },
        component: () => import('@/views/customer/PriceView.vue'),
      },
      {
        path: 'service',
        name: 'service',
        meta: { title: '입원 및 상담안내' },
        component: () => import('@/views/customer/ServiceView.vue'),
      },
    ],
  },
  {
    path: '/press/press_1',
    name: 'press_1',
    component: ()=> import('@/views/info/press/press_1.vue'),
  },
  {
    path: '/press/press_2',
    name: 'press_2',
    component: ()=> import('@/views/info/press/press_2.vue'),
  },
  {
    path: '/press/press_3',
    name: 'press_3',
    component: ()=> import('@/views/info/press/press_3.vue'),
  },
  {
    path: '/press/press_4',
    name: 'press_4',
    component: ()=> import('@/views/info/press/press_4.vue'),
  },
  {
    path: '/press/press_5',
    name: 'press_5',
    component: ()=> import('@/views/info/press/press_5.vue'),
  },
  {
    path: '/press/press_6',
    name: 'press_6',
    component: ()=> import('@/views/info/press/press_6.vue'), 
  },
  {
    path: '/press/press_7',
    name: 'press_7',
    component: ()=> import('@/views/info/press/press_7.vue'),
  },
  {
    path: '/press/press_8',
    name: 'press_8',
    component: ()=> import('@/views/info/press/press_8.vue'),
  },
  {
    path: '/press/press_9',
    name: 'press_9',
    component: ()=> import('@/views/info/press/press_9.vue'),
  },
  {
    path: '/press/press_10',
    name: 'press_10',
    component: ()=> import('@/views/info/press/press_10.vue'),
  },
  {
    path: '/press/press_11',
    name: 'press_11',
    component: ()=> import('@/views/info/press/press_11.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


 
export default router
export { routes }
export function getRouters(){
  
}
