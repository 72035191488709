
<script setup>
import { reactive, onMounted, onUnmounted, watch,watchEffect } from 'vue';
import { routes } from '@/router';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const props = defineProps(['headerlist', 'footerlist']);

const field = reactive({
  menuActive: false,
  sideMenuActive: '',
})
const headList = reactive(props.headerlist)
const subFooter = reactive(props.footerlist)
const toggleHeaer = reactive({
  isOpen: false
})

router.beforeEach((to, from, next) => {
  console.log('header beforeEach to index')
  $('.slide-menu').removeClass('open');
  $('#nav-icon1').removeClass('open');
  $('#overlay').fadeOut('fast');
  if (to.fullPath === '/') {
    document.getElementById('goTop').style.display = 'none';
  } else {
    document.getElementById('goTop').style.display = 'inline-block';
  }
  // field.menuActive = false;
  next()
})

onMounted(() => {
  $(document).on("scroll", function () {
    if ($(document).scrollTop() > 100) {
      $("header").removeClass("large").addClass("small");
    } else {
      $("header").removeClass("small").addClass("large");
    }

  });
  $('#overlay').click(function () {
    $('#nav-icon1').removeClass('open');
    $('.slide-menu').removeClass('open');
    $('#overlay').fadeOut('fast');
  });
  //
  $('#nav-icon1').click(function () {
    $(this).toggleClass('open');
    $('.slide-menu').toggleClass('open');
    $('#overlay').fadeToggle('fast');
  });
  //
  $('#gnb').hover(function () {
    $('#nav-icon1').toggleClass('nav-icon2');
    $('#logo').toggleClass('logo2');
  });
}); //ready
</script>
<template>
  <header class="large">
    <div id="toparea" :value="field.menuActive" @mouseenter="field.menuActive = true"
      @mouseleave="field.menuActive = false">
      <div id="topBox">
        <div class="top_mask">
          <div id="top">
            <div id="header" class="">
              <div class="logo" id="logo"><router-link :to="{ name: 'index' }">
                  <!-- <img v-if="field.menuActive" class="res" src="/logo_veti.png" alt="">
                  <img v-else="field.menuActive" class="res" src="/logo_main_verti.png" alt=""> -->
                </router-link></div>
              <div class="slide-menu" role="사이드메뉴">
                <div class="menu container godwin" id="link">
                  <ul class="mobile-menu-list">
                    <li class="dropdown-mobile menu" v-for="(ele, i) in headList" :key="ele">
                      <div class="depth1 side" @click="field.sideMenuActive = i">
                        <a class="collapsed" :aria-expanded="field.sideMenuActive == i" data-toggle="collapse"
                          :data-target="'#m' + i">
                          {{ ele.title }}<span class="caret"></span>
                        </a>
                      </div>
                      <div class="depth2" style="height:auto">
                        <ul class="dropdown-mobile-menu collapse" :id="'m' + i">
                          <li v-for="sub in ele.subList" :key="sub">
                            <!-- <router-link :to="ele.link + sub.link">{{ sub.title }}</router-link> -->
                            <a :href="ele.link + sub.link">{{ sub.title }}</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="footer">
                  <ul>
                    <li>암스트롱요양병원</li>
                    <li>대표: 손의섭</li>
                    <li>사업자등록번호 : 223-82-03590</li>
                    <li>강원 춘천시 서면 율장길 328 (안보리 312) </li>
                    <li>고객센터: 033-263-8275</li>
                    <li>Copyright 암스트롱요양병원. All rights reserved.</li>
                  </ul>
                  <div class="sns">
                    <a :href="ele.link" v-for="ele in subFooter" :key="ele" target="_blank">
                      <img :src="ele.subSrc" :alt="ele.alt">
                    </a>
                  </div>
                </div>
              </div> <!-- SIDE MENU -->

              <div class="main-overlay"></div>
              <div id="overlay" style="display: none;"></div>
              <div id="nav-icon1">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="gnb dropdown" id="gnb" role="탑메뉴 피씨용">
        <ul class="mobile-menu-list">
          <li class="dropdown-mobile menu" v-for="(ele, i) in props.headerlist">
            <div class="depth1 top">
              <!-- <router-link :to="ele.link + ele.link">{{ ele.title }}</router-link> -->
              <a :href="ele.link + ele.link">{{ ele.title }}</a>
            </div>
            <div class="depth1 side">
              <a class="collapsed" aria-expanded="false" data-toggle="collapse" :data-target="'#m' + i">{{
                ele.title
              }}<span class="caret"></span>
              </a>
            </div>
            <div class="depth2" :class="[field.menuActive ? 'onOver' : '']">
              <ul class="dropdown-mobile-menu  collapse" :id="'m' + i">
                <li v-for="li in ele.subList">
                  <!-- <router-link :to="ele.link + li.link">{{ li.title }}</router-link> -->
                  <a :href="ele.link + li.link">{{ li.title }}</a>
                </li> <!-- li for -->
              </ul>
            </div>
          </li> <!-- list for -->
        </ul>
      </div> <!-- gnb -->

    </div>
  </header>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#topBox .footer .sns a:first-child img {
  color: white;
  background: white;
  border-radius: 32px;
  width: 18px;
  margin: 7px;
}

.menu div a {
  color: white
}

.depth2 {
  transition: height 0.3s ease;
  height: 0;
}

.depth2.onOver {
  height: 255px;
  color: black;
}

@import "@/img/hblock/design/sitemap/css/bootstrap.min.css";
@import "@/img/hblock/design/sitemap/css/style.css";

@import "@/img/hblock/design/menu_04/css/style.css";
@import "@/img/hblock/common/common.css";
@import "@/img/hblock/common/mainLayout.css";
</style>
